
import { getRegionApi } from "@/API/checking/SICCOCheckingDetail";
import { computed, defineComponent, reactive, ref, watch } from "vue";
import useDealerOverview from "@/hooks/rv/useDealerOverview";
import {
  batchRe,
  batchReTrande,
  batchReAll,
  batchReTrandeAll,
  checkForBatchCPO,
  checkForBatchTradeIn,
} from "@/API/userCar";
import { message, Modal } from "ant-design-vue";
import downloadFile from "@/utils/rv/downloadFile";
import batchInputModel from "@/views/UserCar/Components/batchInputModel.vue";
import viewModel from "./Components/viewModel.vue";
import moment from "moment";
import {
  getOverViewTrande,
  getOverView,
  getpageList,
  getpageListTrande,
} from "@/API/userCar";

const tableWidth = window.innerWidth;

export default defineComponent({
  components: { batchInputModel, viewModel },
  props: {
    make: {
      type: String,
      default: "",
    },
    makeId: {
      type: Number,
      required: true,
      default: 1,
    },
    makeYear: {
      type: String,
      default: "",
    },
    makeMonth: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const exportParams = ref<any>();
    const isSearch = ref<boolean>(false);
    const tableHeight = ref<number>(window.innerHeight - 460);
    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 10,
    });
    const size = ref<number>(0);
    const isSelect = ref<string>("0");
    const salesCard = ref<any>({
      submitDate: "NO-Data",
      totalSubmitCount: 0,
      unCheckedCount: 0,
      appealUnCheckedCount: 0,
      unReleasedCount: 0,
      sipoCheckedCount: 0,
      sipoUnCheckedCount: 0,
      sipoReleasedCount: 0,
    });

    const { isOpenPanle, isOpen, activeKey } = useDealerOverview();
    const tableData = reactive<any>([]);
    const tableDataTrande = reactive<any>([]);
    // 表格当前页参数信息
    const tableCurrentInfo = reactive({});

    // 表格选择操作
    const stateSelectedRowKeys = reactive<[]>([]);
    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
      stateSelectedRowKeys.length = 0;
      stateSelectedRowKeys.push(...selectedRows);
    };
    const handleArr = (params: any) => {
      const tempArr: any = reactive([]);
      params.forEach((ele: any) => {
        if (ele.checkStatus == 4) {
          tempArr.push(ele.id);
        }
      });
      return tempArr;
    };
    // 表头数组
    const approvalStatusArray = reactive([
      {
        code: 2,
        msg: "Dealer Submitted",
      },
      {
        code: 3,
        msg: "SICCO Init",
      },
      // {
      //     code: 9,
      //     msg: '已申诉待核查',
      // },
      {
        code: 4,
        msg: "SICCO checked",
      },
      {
        code: 6,
        msg: "SIPO released",
      },
      {
        code: 5,
        msg: "SIPO rejected",
      },
    ]);
    // 审核结果
    const approvalResultArray = reactive([
      {
        code: 0,
        msg: "unchecked",
      },
      {
        code: 1,
        msg: "approved",
      },
      {
        code: 2,
        msg: "rejected",
      },
    ]);
    // CPO销售页签表头
    const columnsCPO = reactive<any>([
      {
        title: "Regions",
        dataIndex: "region",
        align: "center",
        width: 200,
      },
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        align: "center",
        width: 100,
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        align: "center",
        width: 300,
      },
      {
        title: "Seller",
        dataIndex: "seller",
        align: "center",
        width: 100,
      },
      {
        title: "ID No./Legal Entity Code Of Seller",
        dataIndex: "sellerCode",
        align: "center",
        width: 250,
      },

      {
        title: "Certification Date",
        dataIndex: "certificateDate",
        align: "center",
        width: 200,
      },
      {
        title: "VIN No.",
        dataIndex: "vinNo",
        align: "center",
        width: 200,
      },
      {
        title: "Series",
        dataIndex: "series",
        align: "center",
        width: 170,
      },
      {
        title: "Model",
        dataIndex: "typeClass",
        align: "center",
        width: 100,
      },
      {
        title: "1st Registration Date",
        dataIndex: "firstRegistDate",
        align: "center",
        width: 190,
      },
      {
        title: "New Car Warranty Start Date",
        dataIndex: "startRepairDate",
        align: "center",
        width: 220,
      },
      {
        title: "Buyer",
        dataIndex: "buyerName",
        align: "center",
        width: 240,
      },
      {
        title: "Warranty Number",
        dataIndex: "dealerCode",
        align: "center",
        width: 200,
      },
      {
        title: "Certificate No.",
        dataIndex: "certificateCode",
        align: "center",
        width: 150,
      },
      {
        title: "Title Transfer Type",
        dataIndex: "transferType",
        align: "center",
        width: 190,
        slots: { customRender: "transferType" },
      },
      {
        title: "UC Invoice Date",
        dataIndex: "invoiceDate",
        align: "center",
        width: 190,
      },
      {
        title: "UC title Transfer Date",
        dataIndex: "transferDate",
        align: "center",
        width: 190,
      },
      {
        title: "Submission Date",
        dataIndex: "submitDate",
        align: "center",
        width: 120,
      },
      {
        title: "Checking Status",
        dataIndex: "checkStatus",
        align: "center",
        width: 120,
        slots: { customRender: "checkStatus" },
      },
      {
        title: "Checking Result",
        dataIndex: "checkResult",
        align: "center",
        width: 120,
        slots: { customRender: "checkResult" },
      },
      {
        title: "Feedback Comments",
        dataIndex: "feedbackMsg",
        align: "center",
        width: 200,
      },
      {
        title: "Operation",
        dataIndex: "action",
        fixed: "right",
        slots: { customRender: "action" },
        align: "center",
        width: 200,
      },
    ]);
    // 置换页签的表头
    const columns = reactive<any>([
      {
        title: "Regions",
        dataIndex: "region",
        align: "center",
        width: 200,
      },
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        align: "center",
        width: 100,
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        align: "center",
        width: 300,
      },
      {
        title: "Series",
        dataIndex: "series",
        align: "center",
        width: 170,
      },
      {
        title: "Model",
        dataIndex: "typeClass",
        align: "center",
        width: 100,
      },
      {
        title: "UC VIN",
        dataIndex: "usedCarVinNo",
        align: "center",
        width: 100,
      },
      {
        title: "1st Registration Date",
        dataIndex: "firstRegistDate",
        align: "center",
        width: 190,
      },
      {
        title: "UC title Transfer Date",
        dataIndex: "transferDate",
        align: "center",
        width: 190,
      },
      {
        title: "VIN No.",
        dataIndex: "vehicleVinNo",
        align: "center",
        width: 200,
      },
      {
        title: "New Car Invoice Date",
        dataIndex: "newInvoiceDate",
        align: "center",
        width: 190,
      },
      {
        title: "Customer name",
        dataIndex: "seller",
        align: "center",
        width: 190,
      },
      {
        title: "NC buyer Customer Name",
        dataIndex: "buyerName",
        align: "center",
        width: 190,
      },
      {
        title: "Submission Date",
        dataIndex: "submitDate",
        align: "center",
        width: 190,
      },
      {
        title: "Checking Status",
        dataIndex: "checkStatus",
        align: "center",
        width: 120,
        slots: { customRender: "checkStatus" },
      },
      {
        title: "Checking Result",
        dataIndex: "checkResult",
        align: "center",
        width: 120,
        slots: { customRender: "checkResult" },
      },
      {
        title: "Feedback Comments",
        dataIndex: "feedbackMsg",
        align: "center",
        width: 200,
      },
      {
        title: "Operation",
        dataIndex: "action",
        fixed: "right",
        slots: { customRender: "action" },
        align: "center",
        width: 200,
      },
    ]);
    const headCheckState = ref("103");
    // 头部参数信息
    const headerParams = ref<any>({
      quarter: null,
      unSubmit: 103,
    });
    // 提交日期起止时间
    const submitStartDate = ref<unknown>("");
    const submitEndDate = ref<unknown>("");
    const newInvoiceDateFrom = ref<unknown>("");
    const newInvoiceDateTo = ref<unknown>("");
    const delearCode = ref<number>();
    const delearName = ref<string>("");
    const regionVal = ref<string | null>(null);
    const approlResult = ref(null);
    const approlStatus = ref(null);
    const carNo = ref(); //车牌号码
    const vinNo = ref(""); //车架号
    const vinNoArr = ref<any>([]); //车架号数组
    const newVinNo = ref();
    const newVinNoArr = ref<any>([]);
    const typeClass = ref(""); //车牌号码
    //过户时间
    const transformStartDate = ref<unknown>("");
    const transformEndDate = ref<unknown>("");
    const isInputVisible = ref<boolean>(false);
    const isInputVisibleNew = ref(false);
    // 批量查询vinNO
    const batchQueryEvent = (type: string) => {
      if (type === "new") {
        isInputVisibleNew.value = true;
      } else {
        isInputVisible.value = true;
      }
    };

    watch(vinNoArr, (values) => {
      vinNo.value = values.length ? values[0] : "";
    });

    watch(newVinNoArr, (values) => {
      newVinNo.value = values.length ? values[0] : "";
    });

    // 提交日期选择器事件
    const dateChangeSubmit = (params: any) => {
      submitStartDate.value = params[0];
      submitEndDate.value = params[1];
    };
    // 过户日期筛选
    const dateChangeTranstorm = (params: any) => {
      transformStartDate.value = params[0];
      transformEndDate.value = params[1];
    };
    //
    const dateChangeInvoiceNew = (params: any) => {
      newInvoiceDateFrom.value = params[0];
      newInvoiceDateTo.value = params[1];
    };
    // certification日期筛选
    const certificationStartDate = ref<unknown>("");
    const certificationEndDate = ref<unknown>("");
    const dateChangeCertification = (params: any) => {
      certificationStartDate.value = params[0];
      certificationEndDate.value = params[1];
    };
    // new car日期筛选
    const newStartDate = ref<unknown>("");
    const newEndDate = ref<unknown>("");
    const dateChangeNew = (params: any) => {
      newStartDate.value = params[0];
      newEndDate.value = params[1];
    };
    const newInvoiceStartDate = ref<unknown>("");
    const newInvoiceEndDate = ref<unknown>("");
    const dateChangenewInvoice = (params: any) => {
      newInvoiceStartDate.value = params[0];
      newInvoiceEndDate.value = params[1];
    };

    const registrationStartDate = ref<unknown>("");
    const registrationEndDate = ref<unknown>("");
    const dateChangeRegistration = (params: any) => {
      registrationStartDate.value = params[0];
      registrationEndDate.value = params[1];
    };
    const invoiceStartDate = ref<unknown>("");
    const invoiceEndDate = ref<unknown>("");
    const dateChangeInvoice = (params: any) => {
      invoiceStartDate.value = params[0];
      invoiceEndDate.value = params[1];
    };

    // 导出结果  cpo+置换
    const exportResultBtn = (params: any) => {
      if (isSelect.value == "0") {
        const fileName = `XS_(${moment(new Date()).format(
          "YYYYMMDDhhmmss"
        )}).xlsx`;
        downloadFile({
          url: "/rvapi/ucMarketInfo/export?type=3",
          method: "post",
          params: exportParams.value,
          fileName: fileName,
        });
      } else {
        const fileName = `ZH_(${moment(new Date()).format(
          "YYYYMMDDhhmmss"
        )}).xlsx`;
        downloadFile({
          url: "/rvapi/ucReplacementInfo/export?type=3",
          method: "post",
          params: exportParams.value,
          fileName: fileName,
        });
      }
    };
    // 重置筛选条件
    const resetFilter = () => {
      vinNo.value = "";
      vinNoArr.value.length = 0;
      newVinNo.value = "";
      newVinNoArr.value.length = 0;
      typeClass.value = "";
      approlResult.value = null;
      approlStatus.value = null;
      submitStartDate.value = "";
      submitEndDate.value = "";
      transformStartDate.value = "";
      transformEndDate.value = "";
      newInvoiceDateFrom.value = "";
      newInvoiceDateTo.value = "";
      certificationStartDate.value = "";
      certificationEndDate.value = "";
      newStartDate.value = "";
      newEndDate.value = "";
      newInvoiceStartDate.value = "";
      newInvoiceEndDate.value = "";
      registrationStartDate.value = "";
      registrationEndDate.value = "";
      invoiceStartDate.value = "";
      invoiceEndDate.value = "";
      regionVal.value = "";
      delearCode.value = undefined;
      delearName.value = "";
      headerParams.value.quarter = null;
      headerParams.value.unSubmit = 103;
    };

    // 初始化页头统计区域
    const initHeaderCar = () => {
      if (isSelect.value == "1") {
        getOverViewTrande({}).then((res: any) => {
          Object.assign(salesCard.value, res);
        });
      } else {
        getOverView({}).then((res: any) => {
          Object.assign(salesCard.value, res);
        });
      }
    };
    // 初始化表格信息
    const getTableData2 = (params: any) => {
      initHeaderCar(); //初始化页头卡片信息
      pagination.currentPage = 1;
      // cpo销售
      if (isSelect.value == "0") {
        // cpo销售清单信息
        const tempObj = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          checkStatus: headerParams.value.unSubmit,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: "",
          dealerId: "",
          size: pagination.pageSize,
          vinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          quarter: null,
        };
        exportParams.value = tempObj;
        getpageList(tempObj).then((res: any) => {
          Object.assign(tableCurrentInfo, tempObj);
          tableData.length = 0;
          tableData.push(...res.content);
          pagination.pageSize = res.size;
          pagination.currentPage = res.totalPages >= 1 ? res.totalPages - 1 : 0;
          pagination.total = res.totalElements;
        });
      } else {
        const tempObj1 = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          newInvoiceDateFrom: newInvoiceDateFrom.value,
          newInvoiceDateTo: newInvoiceDateTo.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          usedCarVinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          vehicleVinNos: newVinNoArr.value.length
            ? newVinNoArr.value
            : [newVinNo.value],
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headerParams.value.unSubmit,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: "",
          dealerId: "",
          size: pagination.pageSize,
          quarter: null,
        };
        exportParams.value = tempObj1;
        // 置换清单信息
        getpageListTrande(tempObj1).then((res: any) => {
          Object.assign(tableCurrentInfo, tempObj1);
          tableDataTrande.length = 0;
          tableDataTrande.push(...res.content);
          pagination.pageSize = res.size;
          pagination.currentPage = res.totalPages >= 1 ? res.totalPages - 1 : 0;
          pagination.total = res.totalElements;
        });
      }
    };
    // 初始化表格信息
    const getTableData = (params: any) => {
      initHeaderCar(); //初始化页头卡片信息
      // pagination.currentPage = 1;
      // cpo销售
      if (isSelect.value == "0") {
        // cpo销售清单信息
        const tempObj = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          region: regionVal.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headCheckState.value,

          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: delearCode.value,
          dealerId: "",
          size: pagination.pageSize,
          vinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          quarter: null,
          model: typeClass.value,
          certificationDateFrom: certificationStartDate.value,
          certificationDateTo: certificationEndDate.value,
          firstRegistDateFrom: registrationStartDate.value,
          firstRegistDateTo: registrationEndDate.value,
          startRepairDateFrom: newStartDate.value,
          startRepairDateTo: newEndDate.value,
          dealerName: delearName.value,
        };
        exportParams.value = tempObj;
        getpageList(tempObj).then((res: any) => {
          Object.assign(tableCurrentInfo, tempObj);
          tableData.length = 0;
          tableData.push(...res.content);
          pagination.pageSize = res.size;
          // pagination.currentPage =
          //     res.totalPages >= 1 ? res.totalPages - 1 : 0;
          pagination.total = res.totalElements;
        });
      } else {
        const tempObj1 = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          newInvoiceDateFrom: newInvoiceDateFrom.value,
          newInvoiceDateTo: newInvoiceDateTo.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          usedCarVinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          vehicleVinNos: newVinNoArr.value.length
            ? newVinNoArr.value
            : [newVinNo.value],
          vehicleLicense: carNo.value,
          region: regionVal.value,
          checkResult: approlResult.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headCheckState.value,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: delearCode.value,
          dealerId: "",
          size: pagination.pageSize,
          quarter: null,
          model: typeClass.value,
          certificationDateFrom: certificationStartDate.value,
          certificationDateTo: certificationEndDate.value,
          firstRegistDateFrom: registrationStartDate.value,
          firstRegistDateTo: registrationEndDate.value,
          startRepairDateFrom: newStartDate.value,
          startRepairDateTo: newEndDate.value,
          dealerName: delearName.value,
        };
        exportParams.value = tempObj1;
        // 置换清单信息
        getpageListTrande(tempObj1).then((res: any) => {
          Object.assign(tableCurrentInfo, tempObj1);
          tableDataTrande.length = 0;
          tableDataTrande.push(...res.content);
          pagination.pageSize = res.size;
          pagination.total = res.totalElements;
        });
      }
    };

    const reBatchRequest = (record: any, type: number) => {
      const params = {
        ids: record ? [record.id] : handleArr(stateSelectedRowKeys),
        status: type,
      };
      if (isSelect.value == "0") {
        batchRe(params).then(() => {
          getTableData({});
          message.success("handle successful!");
        });
      } else {
        batchReTrande(params).then(() => {
          getTableData({});
          message.success("handle successful!");
        });
      }
    };
    // 发起批量拒绝或发布的请求事件
    const batchReRequest = (type: number) => {
      const batchReHandlers = {
        0: batchReAll,
        1: batchReTrandeAll,
      };
      const params = {
        status: type,
        data: exportParams.value,
      };
      batchReHandlers[isSelect.value](params).then(() => {
        getTableData({});
        message.success("handle successful!");
      });
    };

    const showBatchModal = (ok: () => void, type: number) => {
      Modal.confirm({
        title: "提示",
        okText: "Confirm",
        content: `Do you confirm batch ${
          type === 6 ? "release" : "reject"
        } all search result?`,
        onOk() {
          ok();
        },
      });
    };

    const selectAll = computed(() => {
      return stateSelectedRowKeys.length >= 1;
    });

    const checkStatusChecker = (type: number) => {
      // 返回 true 表示校验未通过
      if (selectAll.value) {
        const cannot = stateSelectedRowKeys.find(
          (item: any) => item.checkStatus !== 4
        );
        if (cannot) {
          message.error(
            `Only SICCO checked data can be ${
              type === 6 ? "release" : "reject"
            }`
          );
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      } else {
        const params = {
          status: type,
          data: exportParams.value,
        };
        const checkForBatch = {
          0: checkForBatchCPO,
          1: checkForBatchTradeIn,
        };
        return checkForBatch[isSelect.value](params)
          .then(() => {
            return false;
          })
          .catch(() => {
            return true;
          });
      }
    };
    // 单车拒绝发布
    const Handler = async (type: number, record: any) => {
      showBatchModal(() => reBatchRequest(record, type), type);
    };
    // 批量拒绝发布
    const batchHandler = async (type: number) => {
      const status = await checkStatusChecker(type);
      if (status) {
        return;
      }
      showBatchModal(() => batchReRequest(type), type);
    };
    // 批量发布
    // const batchRelease = (type: number, record: any) => {
    //   if (record || stateSelectedRowKeys.length) {
    //     for (let i = 0; i < stateSelectedRowKeys.length; i++) {
    //       if ((stateSelectedRowKeys[i] as any).checkStatus !== 4) {
    //         message.error("Only SICCO checked data can be relese");
    //         return;
    //       }
    //     }

    //     const tempParams = {
    //       ids: record ? [record.id] : handleArr(stateSelectedRowKeys),
    //       status: type,
    //     };
    //     reBatchRequest(tempParams);
    //   } else {
    //     batchReRequest(type);
    //   }
    // };
    // 头部卡片搜索
    const searchSub = () => {
      getTableData(tableCurrentInfo);
    };
    getTableData({});
    // 头部卡片蓝色数字点击数据穿透查询
    const showInfo = (type: number) => {
      isSearch.value = true;
      if (type == 1) {
        // 如果是点击的总计数字发起查询，全部清空，除年份和季度之外
        headerParams.value.unSubmit = 103;
        headCheckState.value = "103";
        resetFilter(); //全部清空
      } else if (type == 2) {
        // type为2表示unRelease,传55
        resetFilter(); //全部清空
        headerParams.value.unSubmit = 55;
        headCheckState.value = "55";
      } else if (type == 3) {
        // type为3表示Checked,传106
        resetFilter(); //全部清空
        headerParams.value.unSubmit = 106;
        headCheckState.value = "106";
      } else if (type == 4) {
        // type为4表示UnChecked,传107
        resetFilter(); //全部清空
        headerParams.value.unSubmit = 107;
        headCheckState.value = "107";
      } else if (type == 5) {
        // type为5表示Released,传108
        resetFilter(); //全部清空
        headerParams.value.unSubmit = 108;
        headCheckState.value = "108";
      }
      getTableData2({
        unReleasedCount: headerParams.value.unSubmit,
      });
      resetFilter(); //全部清空
    };
    // 切换页签
    const changeTab = (param: string) => {
      console.log();
      pagination.currentPage = 1;
      isSelect.value = param;
      isOpen.value = false;
      // 批量下载按钮不可用
      isSearch.value = false;
      // tableHeight.value = 280;
      if (param == "1") {
        tableHeight.value = window.innerHeight - 440;
      } else {
        tableHeight.value = window.innerHeight - 450;
      }
      resetFilter(); //重置筛选条件
      getTableData(tableCurrentInfo); //刷新表格
    };
    // 处理表格高度
    const refreshTableHeight = () => {
      if (isOpen.value) {
        if (isSelect.value == "0") {
          tableHeight.value = window.innerHeight - 580;
        } else {
          tableHeight.value = window.innerHeight - 550;
        }
      } else {
        if (isSelect.value == "1") {
          tableHeight.value = window.innerHeight - 440;
        } else {
          tableHeight.value = window.innerHeight - 460;
        }
      }
    };
    // 筛选条件查询事件
    const searchTable = () => {
      pagination.currentPage = 1;
      const tempObj = {
        checkResult: approlResult.value,
        checkStatus: approlStatus.value
          ? approlStatus.value
          : headerParams.value.unSubmit,
        submitDateFrom: submitStartDate.value,
        submitDateTo: submitEndDate.value,
        transformDateFrom: transformStartDate.value,
        transformDateTo: transformEndDate.value,
        vinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
        usedCarVinNo: typeClass.value,
        vehicleLicense: carNo.value,
        size: pagination.pageSize,
        current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
        usedCarVinNos: vinNoArr.value,
      };
      // 批量下载按钮可用
      isSearch.value = true;
      getTableData(tempObj);
      // 如果是点击的总计数字发起查询，全部清空，除年份和季度之外
      headerParams.value.unSubmit = null;
      // resetFilter(); //全部清空
      isOpen.value = false;
      refreshTableHeight();
    };

    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page;
      stateSelectedRowKeys.length = 0;
      getTableData(tableCurrentInfo);
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      stateSelectedRowKeys.length = 0;
      getTableData(tableCurrentInfo);
    };

    const regionArray = ref<any[]>([]);
    //获取region数组
    const getRegion = () => {
      getRegionApi({ bu: "CPO" }).then((res) => {
        console.log(res);
        regionArray.value = res;
      });
    };
    getRegion();

    const isViewVisible = ref(false);
    const viewList = ref<any>({});
    const viewClose = () => {
      isViewVisible.value = false;
    };
    // 核查操作框 行间按钮
    const viewModelShow = (param: any) => {
      // 弹窗出现前先改变核查状态
      isViewVisible.value = true;
      viewList.value = param;
      Object.assign(viewList.value, {
        types: isSelect.value,
        operationType: isSelect.value === "0" ? 1 : 2,
      });
    };

    const checkResultStr = (record: { checkResult: number }) => {
      switch (record.checkResult) {
        case 0:
          return "Unchecked";
        case 1:
          return "Approved";
        case 2:
          return "Rejected";
        default:
          return "";
      }
    };

    return {
      regionArray,
      exportResultBtn,
      isSearch,
      refreshTableHeight,
      tableWidth,
      tableHeight,
      pagination,
      isSelect,
      changeTab,
      getTableData,
      salesCard,
      showInfo,
      activeKey,
      sizeChange,
      pageChange,
      size,
      isOpenPanle,
      Handler,
      batchHandler,
      isOpen,
      columns,
      columnsCPO,
      tableData,
      tableDataTrande,
      typeClass,
      transformStartDate,
      transformEndDate,
      vinNo,
      vinNoArr,
      approlResult,
      regionVal,
      delearCode,
      delearName,
      batchQueryEvent,
      isInputVisible,
      dateChangeTranstorm,
      certificationStartDate,
      certificationEndDate,
      dateChangeCertification,
      newStartDate,
      newEndDate,
      dateChangeNew,
      newInvoiceStartDate,
      newInvoiceEndDate,
      dateChangenewInvoice,
      registrationStartDate,
      registrationEndDate,
      dateChangeRegistration,
      invoiceStartDate,
      invoiceEndDate,
      dateChangeInvoice,
      dateChangeSubmit,
      submitStartDate,
      submitEndDate,
      resetFilter,
      searchTable,
      tableCurrentInfo,
      searchSub,
      approvalResultArray,
      approvalStatusArray,
      onSelectChange,
      stateSelectedRowKeys,
      carNo,
      approlStatus,
      isInputVisibleNew,
      newVinNo,
      newVinNoArr,
      dateChangeInvoiceNew,
      newInvoiceDateFrom,
      newInvoiceDateTo,
      isViewVisible,
      viewList,
      viewClose,
      viewModelShow,
      checkResultStr,
    };
  },
});
