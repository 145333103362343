
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { b64toBlob } from "@/utils/claim/b64toBlob";
import {
  viewFile,
  operationRecord,
  viewFileTrande,
  appealRecordTrande,
  appealRecord,
  checkSingleTrande,
  checkSingle,
  cpoSalesFeedbackInfo,
  tradeInFeedbackInfo,
} from "@/API/userCar";
import moment from "moment";
import downloadFile from "@/utils/rv/downloadFile";
export default defineComponent({
  emits: ["update:visible", "close-view-visible", "init-table"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    isViewData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  setup(props, { emit }) {
    const aUrl = ref<string>("");
    const commentModalVisible = ref(false);
    const failFeedback = ref("");
    const columns = [
      { title: "操作角色", dataIndex: "operationRole", align: "center" },
      {
        title: "操作动作",
        dataIndex: "operationAction",
        align: "center",
        width: 100,
      },
      { title: "操作时间", dataIndex: "updatedAt", align: "center" },
    ];
    const dataSource = ref([]);
    const viewList = ref<any>([]);

    //申诉信息描述返回
    const appealMsg = ref<string>("");
    // 审核表格
    const checkForm = reactive<any>({
      checkResult: undefined,
      checkMsg: [],
    });

    const title = ref<string>("支持文档信息");
    const isTab = ref<number>(0);

    // 处理图片文件数组
    const handleImgArr = (arr: any) => {
      viewList.value = [];
      arr.forEach((ele: any) => {
        const temp = {
          imgUrl: "",
          downUrl: "",
          docType: true,
          desType: ele.desType,
        };
        if (ele.base64) {
          temp.imgUrl = URL.createObjectURL(
            b64toBlob(ele.base64, "application/pdf")
          );
          temp.docType = false;
        } else {
          temp.imgUrl = ele.downloadUrl;
          temp.docType = true;
        }
        temp.downUrl = ele.downloadUrl;
        viewList.value.push(temp);
        return viewList.value;
      });
      aUrl.value = viewList.value[0].downUrl;
    };
    // 初始化可查看页面 cpo+置换
    const init = async () => {
      if (props.isViewData.types == 0) {
        // cpo销售的
        viewFile({
          id: props.isViewData.id,
          type: props.isViewData.types,
        }).then((res: any) => {
          if (res.length) {
            handleImgArr(res);
          }
        });
      } else {
        // 置换的查看
        viewFileTrande({
          id: props.isViewData.id,
          type: 0, // 0代表查询所有文件
        }).then((res: any) => {
          if (res.length) {
            handleImgArr(res);
          }
        });
      }

      const feedback =
        props.isViewData.types == 0
          ? await cpoSalesFeedbackInfo(props.isViewData.id)
          : await tradeInFeedbackInfo(props.isViewData.id);
      if (feedback === "审核通过") {
        checkForm.checkResult = "核查通过";
      } else {
        failFeedback.value = feedback;
        checkForm.checkResult = "核查未通过";
      }
    };
    // 初始化操作记录信息 cpo+置换
    const initRecord = () => {
      // cpo销售+置换通用接口
      operationRecord({
        mainId: props.isViewData.id,
        type: props.isViewData.operationType,
      }).then((res) => {
        dataSource.value = res;
      });
    };
    // 下载全部图片
    const downLoadAllImg = () => {
      const params = {
        url: viewList.value[0].downloadUrl,
        method: "get",
        fileName: `${moment(new Date()).format("YYYYMMDDhhmmss")}${{
          title,
        }}.jpg`,
      };
      downloadFile(params);
    };

    // 取消审核
    const cancel = () => {
      checkForm.checkResult = undefined;
      checkForm.checkMsg = [];
      emit("update:visible", false); //取消审核关闭弹窗
    };
    // 申诉记录审核文档
    const docList = ref([]);
    const getRecordAppeal = () => {
      if (props.isViewData.types == 0) {
        //  cpo
        appealRecord({ id: props.isViewData.id }).then((res) => {
          const data = res.docList;
          for (let i = 0; i < data.length; i++) {
            if (data[i].docType === "PDF") {
              data[i].base64 = URL.createObjectURL(
                b64toBlob(data[i].base64, "application/pdf")
              );
            }
          }
          docList.value = data;
          appealMsg.value = res.appealMsg;
        });
      } else {
        //  置换
        appealRecordTrande({ id: props.isViewData.id }).then((res) => {
          const data = res.docList;
          for (let i = 0; i < data.length; i++) {
            if (data[i].docType === "PDF") {
              data[i].base64 = URL.createObjectURL(
                b64toBlob(data[i].base64, "application/pdf")
              );
            }
          }
          docList.value = data;
          appealMsg.value = res.appealMsg;
        });
      }
    };

    // 切换页签
    const changeTab = (param: number) => {
      isTab.value = param;
      if (param == 1) {
        initRecord();
      } else if (param == 2) {
        getRecordAppeal();
      } else if (param == 0) {
        init();
      }
    };
    changeTab(0); //初始化

    return {
      viewList,
      title,
      close,
      downLoadAllImg,
      checkForm,
      changeTab,
      isTab,
      getRecordAppeal,
      dataSource,
      columns,
      aUrl,
      docList,
      cancel,
      appealMsg,
      commentModalVisible,
      failFeedback,
    };
  },
});
